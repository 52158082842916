<!--
 * @Author: Han
 * @Date: 2022-05-11 14:13:56
 * @LastEditors: Han
 * @LastEditTime: 2022-05-12 18:42:55
 * @FilePath: \GithubProject\apiadmin\src\App.vue
-->
<template>
  <div>
    <el-tag effect="dark">自从发了抖音到现在，已经过去了4天。</el-tag>
    <el-tag type="success" effect="dark">这4天每天10万➕用户使用本程序,这是令我万万没想到的！</el-tag>
    <el-tag type="warning" effect="dark">原本只是娱乐,没想到服务器给我整崩了好多次！</el-tag>
    <el-tag type="danger" effect="dark">感谢各位用户的认可，及使用！所以更新了最新系统！</el-tag>
    <el-tag type="info" effect="dark">详细教程及最新版稳定地址！</el-tag>
    <div style="padding-top: 16px">
      <h3>点击链接</h3>
      <el-button type="primary" @click="goBlog">点击前往最新地址</el-button>
      <h3>或者扫码</h3>
      <el-image style="width: 188px; height: 188px" src="https://api.vvhan.com/api/qr?text=https://www.vvhan.com/yanglegeyang-shua1.html" fit="cover" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBlog() {
      window.open("https://www.vvhan.com/yanglegeyang-shua1.html");
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  margin-bottom: 6px;
}
</style>
